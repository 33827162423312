import { PrevNextButton } from "../../components";

export default function AreasOfIntervention({translations}) {

  return (
    <section className='areas-of-intervention'>
      <div className='areas-of-intervention__container'>
        <h2 className='areas-of-intervention__title title'>{translations.areas_of_expertise__title}</h2>
        <div className='areas-of-intervention__texts'>
            <p>{translations.areas_of_expertise__first_column_1}</p>

          <div>
            <p>{translations.areas_of_expertise__second_column_1}</p>
            <p>{translations.areas_of_expertise__second_column_2}</p>
          </div>
          
          <p>{translations.areas_of_expertise__third_column_1}</p>
        </div>
      </div>
      <PrevNextButton position="left" path="/"/>
      <PrevNextButton position="right" path="/team"/>
    </section>
  )
}
