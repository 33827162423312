function NorwegianIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 223 225"
    >
      <path
        fill="#24408F"
        d="M95.333 1.14C91.6 1.674 87.2 2.474 85.6 3.007l-2.933.933V99.807h140.266L222 93.54c-1.467-10-5.867-23.066-11.467-33.866-21.333-41.2-68.8-65.334-115.2-58.534zM50.667 17.94c-24 15.734-41.067 39.467-48 66.534-4.4 16.933-7.2 15.333 27.333 15.333h30V56.474c0-23.867-.4-43.334-1.067-43.334-.533 0-4.266 2.134-8.266 4.8zM0 125.407c.133 10 6.933 30 15.067 44.4 6.533 11.333 27.866 33.067 38 38.4L60 211.94v-89.466H0v2.933zM82.667 171.674v49.333l7.733 1.6c4.133.8 14.267 1.467 22.267 1.467 51.066-.4 94.8-34.534 107.466-84 1.334-5.467 2.534-11.734 2.534-13.867v-3.733h-140v49.2z"
      />
    </svg>
  );
}

export default NorwegianIcon;
