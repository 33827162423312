import { AreasOfIntervention, Home, Contact, Fees, Team } from '../index';

export default function Mobile({translations, isMobile}) {
  return (
    <section className='mobile-page'>
      <Home translations={translations} />
      <AreasOfIntervention translations={translations} />
      <Team translations={translations} />
      <Fees translations={translations} />
      <Contact translations={translations} isMobile={isMobile}/>
    </section>
  )
}
