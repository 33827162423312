function EnglishIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 223 224"
    >
      <path
        fill="#24408F"
        d="M87.333 2.267c-5.066 1.066-13.6 3.733-18.933 6-10 4.133-25.733 13.6-25.733 15.333 0 .667 12.933 14 28.666 29.733L100 82V40.933C100 9.067 99.6 0 98.4.133c-.933 0-5.867.934-11.067 2.134zM122.667 41.6v41.733L152 54c23.6-23.6 28.933-29.6 27.733-31.067C174 16.133 143.867 3.2 128.4.8l-5.733-.8v41.6zM17.867 50.267C14.133 56 9.733 64 8 68c-3.467 8.267-8 24.533-8 28.933V100h86L56 70C39.467 53.467 25.733 40 25.333 40c-.4 0-3.866 4.667-7.466 10.267zM168 70l-30 30H222.933L222 93.733C219.733 78.8 211.733 59.467 202.4 46.4l-4.533-6.267L168 70zM0 125.733C0 136.8 12.267 168 20.533 178l3.334 4L53.6 152.267l29.733-29.6H0v3.066zM170 152l29.333 29.467 3.067-3.734c8-9.866 20.267-41.066 20.267-52v-3.066h-82L170 152zM122.667 180.4c0 41.067.133 43.733 2.4 43.067 1.2-.267 4.933-1.067 8.266-1.467 13.334-2.133 32.534-10.667 44.934-20l5.6-4.267L153.2 167.2l-30.533-30.533V180.4zM69.6 168.4l-29.867 29.867 4.134 3.333C54 210.267 73.6 218.933 90 222c5.067 1.067 9.467 1.6 9.733 1.467.134-.267.134-19.334 0-42.667l-.4-42.133L69.6 168.4z"
      />
    </svg>
  );
}

export default EnglishIcon;
