function PrevNextIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="682.667"
      height="682.667"
      version="1"
      viewBox="0 0 512 512"
    >
      <path
        fill="#FFF"
        d="M1400 5098c-44-17-77-44-171-137-144-143-163-177-164-286 0-58 5-91 19-120 13-27 333-355 995-1018l976-977-977-978c-760-760-982-987-997-1022-14-30-21-67-21-110 0-103 29-153 168-291 98-97 127-119 175-137 73-28 131-28 204-1 56 20 108 71 1230 1193 1297 1296 1223 1214 1223 1346s74 50-1223 1346C1714 5029 1663 5079 1607 5099c-72 26-136 26-207-1z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
}

export default PrevNextIcon;
