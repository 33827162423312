export default function BurgerMenu({isOpen}) {
  return (
    <div className='burger-menu'>
      {isOpen 
        ? <div 
            className='burger-menu__close'
          >
            <div></div>
            <div></div>
          </div>
        : <div 
            className='burger-menu__open'
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
      }
    </div>
  )
}
