import { PrevNextButton } from "../../components";

export default function Contact({translations, isMobile}) {

  return (
    <section className="contact">
      <div className="contact__principal-container">
      {isMobile && <h2 className='contact__principal-container__title title'>{translations.contact__title}</h2>}
        <div className="contact__little-container">
          <h2 className="title">{translations.contact__french_office__title}</h2>
          <div className="contact__little-container__bottom">
            <div>
              <p dangerouslySetInnerHTML={{ __html: translations.contact__french_office__contact }} />
              <p className="mail">
                E-mail:
                <a href={`mailto:nina.wang@avocat-taylor.com`}>
                  nina.wang@avocat-taylor.com
                </a>
              </p>
            </div>
            <img src="/ParisMap.webp" alt="Paris"/>
          </div>
        </div>

        <div className="contact__little-container">
          <h2 className="title">{translations.contact__norwegian_office__title}</h2>
          <div  className="contact__little-container__bottom">
            <div>
              <p dangerouslySetInnerHTML={{ __html: translations.contact__norwegian_office__contact }} />
              <p className="mail">
                E-mail:
                <a href={`mailto:nina.wang@avocat-taylor.com`}>
                  nina.wang@avocat-taylor.com
                </a>
              </p>
            </div>
            <img src="/OsloMap.webp" alt="Oslo"/>
          </div>
        </div>
      </div>

      <PrevNextButton position="left" path="/fees"/>
    </section>
  )
}
