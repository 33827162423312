import { EnglishIcon, FrenchIcon, NorwegianIcon } from '../../assets';
import { useLanguage } from '../../context/LanguageContext';

const LanguageSelector = () => {
  const { changeLanguage, languageSelected } = useLanguage();
  
  return (
    <div className='language-selector'>
      <button 
        style={{ opacity: languageSelected === "fr" ? 1 : 0.5 }}
        onClick={() => changeLanguage('fr')}
      >
        <FrenchIcon/>
      </button>
      <button 
        style={{ opacity: languageSelected === "no" ? 1 : 0.5 }} 
        onClick={() => changeLanguage('no')}
        >
          <NorwegianIcon/>
      </button>
      <button 
        style={{ opacity: languageSelected === "en" ? 1 : 0.5 }} 
        onClick={() => changeLanguage('en')}
      >
        <EnglishIcon/>
      </button>
    </div>
  );
};

export default LanguageSelector;