import { PrevNextButton } from "../../components";

export default function Fees({translations}) {
  return (
    <section className='fees'>
      <div className='fees__container'>
        <h2 className='fees__title title'>{translations.the_fees__title}</h2>
        <div className='fees__text'>
          <div>
            <p>{translations.the_fees__first_column_1}</p>
            <p>{translations.the_fees__first_column_2}</p>
          </div>

          <div>
            <p>{translations.the_fees__second_column_1}</p>
            <p>{translations.the_fees__second_column_2}</p>
          </div>
        </div>
      </div>
      <PrevNextButton position="left" path="/team"/>
      <PrevNextButton position="right" path="/contact"/>
    </section>
  )
}
