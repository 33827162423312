import { Link } from "react-router-dom";
import { PrevNextIcon } from "../../assets";

export default function PrevNextButton({path, position}) {
  return (
    <div className={`prev-next-button prev-next-button--${position}`}>
      <Link to={path}>
        <PrevNextIcon/>
      </Link>
    </div>
  )
}
