import { Route, Routes } from 'react-router-dom';
import { AreasOfIntervention, Home, Contact, Fees, Team, Mobile } from "./views";
import { Navbar, Footer } from './components/index'
import './views/index.css'
import { useLanguage } from './context/LanguageContext';


function App() {
  const { language } = useLanguage();
  const translations = require(`./locales/${language}.json`);
  
  const pageWidth = window.innerWidth;
  const isMobile = pageWidth < 600

  return (
    <>
      <Navbar />
      <div className='mobile-layout'>
        <Mobile translations={translations} isMobile={isMobile}/> 
      </div>
      <div className='laptop-layout'>
        <Routes>
          <Route path="/" element={<Home translations={translations} />} />
          <Route path="/areas" element={<AreasOfIntervention translations={translations} />} />
          <Route path="/team" element={<Team translations={translations} />} />
          <Route path="/fees" element={<Fees translations={translations} />} />
          <Route path="/contact" element={<Contact translations={translations} />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;