import { Link, useLocation } from "react-router-dom";
import { WanglawLogo } from "../../assets";
import { useLanguage } from '../../context/LanguageContext';
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { BurgerMenu, NavbarSeparator } from '../index'
import { useEffect, useState } from "react";


export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false)
  
  const { language } = useLanguage();
  const translations = require(`../../locales/${language}.json`);
  const location = useLocation();
  const selected = location.pathname

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('navbar-open');
    } else {
      document.body.classList.remove('navbar-open');
    }
  }, [isOpen]);

  const handleMenu = () => {
    setIsOpen(!isOpen)
  }

  const scrollToSection = (section) => {
    const targetSection = document.querySelector(`.${section}`);
    if (targetSection) {
      const paddingTop = 120;
      const scrollPosition = targetSection.offsetTop - paddingTop;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
    setIsOpen(false)
  }

  const menu = [
    {
      label: translations.navbar__areas_of_expertise,
      href: '/areas',
      section: 'areas-of-intervention'
    },
    {
      label: translations.navbar__the_team,
      href: '/team',
      section: 'team'
    },
    {
      label: translations.navbar__the_fees,
      href: '/fees',
      section: 'fees'
    },
    {
      label: translations.navbar__contact,
      href: '/contact',
      section: 'contact' 
    }
  ] 

  return (
    <header className="navbar">
      <div className="navbar__logo">
        <Link to="/" onClick={() => scrollToSection('home')}><WanglawLogo/></Link>
      </div>

      <nav className="navbar__menu laptop">
        {menu.map((m, i) => {
          return (
            <>
              <Link 
                to={m.href} 
                style={
                  { opacity: selected === m.href ||  selected === "/"
                    ? '1' : '0.5' 
                  }
                }
              >
                {m.label}
              </Link>
              {i !== menu.length - 1 && <NavbarSeparator/>}
            </>
          )
        })}
      </nav>

      <div className="navbar__language-selector-laptop">
        <LanguageSelector/>
      </div>

      <div className="burger-menu-mobile" onClick={handleMenu}>
        <BurgerMenu isOpen={isOpen} />
      </div>

      <nav className={`navbar__mobile ${isOpen ? 'open' : 'close'}`}>
        <div className="navbar__mobile__container">
          {menu.map((m, i) => {
              return (
                <>
                  <Link 
                    to={m.href} 
                    onClick={() => scrollToSection(m.section)}
                  >
                    {m.label}
                  </Link>
                </>
              )
            })}
            <LanguageSelector/>
        </div>
      </nav>
    </header>
  )
}