function FrenchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 224 225"
    >
      <path
        fill="#24408F"
        d="M99.562 1.219c-5.067.533-11.867 1.733-15.067 2.533l-5.6 1.6.267 107.6.4 107.6 7.333 1.733c9.734 2.534 41.067 2.534 50 .134l6.667-1.867.4-107.6.267-107.6-5.334-1.467c-5.2-1.466-26-4.133-28.666-3.866-.667.133-5.467.666-10.667 1.2zM47.695 20.552c-21.333 14.8-38 39.067-44.8 65.333-3.6 13.734-3.866 37.067-.666 51.2 6.533 27.867 23.066 52.534 45.866 68l8.134 5.6v-97.466c0-53.467-.4-97.334-.934-97.2-.666 0-4 2.133-7.6 4.533zM166.895 113.352v97.333l4.134-2.4c7.733-4.533 20.933-16.266 27.866-24.666 8.134-10 18.8-31.334 22.134-44.4 1.866-6.934 2.4-14 2.4-27.334-.134-15.466-.667-19.733-3.734-29.733-5.866-19.733-15.466-35.867-29.066-49.2-7.2-7.2-20.4-17.067-22.667-17.067-.667 0-1.067 43.867-1.067 97.467z"
      />
    </svg>
  );
}

export default FrenchIcon;
