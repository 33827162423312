import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const navigatorLanguage = navigator.language.split('-')[0];
  const languageMap = {
    fr: 'fr',
    no: 'no',
  };

  const defaultLanguage = languageMap[navigatorLanguage] || 'en';

  const savedLanguage = localStorage.getItem('selectedLanguage');

  const [language, setLanguage] = useState(savedLanguage || defaultLanguage);
  const [languageSelected, setLanguageSelected] = useState(savedLanguage || defaultLanguage);

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    setLanguageSelected(newLanguage);

    localStorage.setItem('selectedLanguage', newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, languageSelected }}>
      {children}
    </LanguageContext.Provider>
  );
};