import { PrevNextButton } from "../../components";

export default function Home({translations}) {
  
  return (
    <section className="home">
      <img className="home__image" src="/HomeBackground.webp" alt="home"/>
      <img className="home__image--mobile" src="/HomeBackgroundMobile.webp" alt="home"/>
      <h1 className='home__title title'>{translations.home__title}</h1>
      <PrevNextButton position="right" path="/areas"/>
    </section>
  )
}
