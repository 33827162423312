import { PrevNextButton } from "../../components";

export default function Team({translations}) {
  const pageWidth = window.innerWidth;
  const isLaptop = pageWidth > 1000

  return (
    <section className="team">
      {isLaptop ?
        <div className="team__container">
          <div className="team__container__left">
            <h2 className="title">{translations.the_team__title}</h2>
            <img src="/NinaWang.webp" alt="Nina Wang"/>
          </div>
          
          <div  className="team__container__center">
            <h2 className="title">Nina Wang</h2>

            <div>
              <p dangerouslySetInnerHTML={{ __html: translations.the_team__nina_wang_1 }} />
              <p dangerouslySetInnerHTML={{ __html: translations.the_team__nina_wang_2 }} />
              <p dangerouslySetInnerHTML={{ __html: translations.the_team__nina_wang_3 }} />
            </div>
          </div>
          
          <div  className="team__container__right">
            <h2 className="title">Bronwen Perret-Deschamps</h2>
            <div>
              <img src="/BronwenPerretDeschamps.webp" alt="Bronwen Perret-Deschamps"/>
              <p dangerouslySetInnerHTML={{ __html: translations.the_team__bronwen }} />
            </div>
          </div>
        </div>
        :
        <div className="team__container">
          <div className="team__container__left">
            <h2 className="title">{translations.the_team__title}</h2>
          </div>

          <div  className="team__container__center">
            <h3 className="title">Nina Wang</h3>
            <img src="/NinaWang.webp" alt="Nina Wang"/>
            <div>
              <p dangerouslySetInnerHTML={{ __html: translations.the_team__nina_wang_1 }} />
              <p dangerouslySetInnerHTML={{ __html: translations.the_team__nina_wang_2 }} />
              <p dangerouslySetInnerHTML={{ __html: translations.the_team__nina_wang_3 }} />
            </div>
          </div>
          
          <div  className="team__container__right">
            <h3 className="title">Bronwen Perret-Deschamps</h3>
            <img src="/BronwenPerretDeschamps.webp" alt="Bronwen Perret-Deschamps"/>

            <div>
              <p dangerouslySetInnerHTML={{ __html: translations.the_team__bronwen }} />
            </div>
          </div>
        </div>
      }
      
      <PrevNextButton position="left" path="/areas"/>
      <PrevNextButton position="right" path="/fees"/>
    </section>
  )
}
